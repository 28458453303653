@import "assets/mixins";
@import "assets/header";
@import "assets/footer";
@import "assets/startseite";
@import "assets/listen";

.ap-contact {
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: $font-content-size;
  }
}
