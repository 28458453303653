@mixin liste {
  .list-entry {
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 30px;
    .ap-teaser {
      position: relative;
      .teaser-body {
        position: initial;
        .btn {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}

.stg_tagesimpuls-liste {
  @include ecke(55px, 85px, $color-lead1);
  @include liste;
}

.stg_nachrichten-liste {
  @include ecke(85px, 55px, $color-lead4);
  @include liste;
  .teaser-body .btn {
    background-color: $color-lead4;
    &:hover, &:focus {
      background-color: darken($color-lead4, 20);
    }
  }
}

.stg_termine-liste {
  @include ecke(55px, 45px, $color-lead3);
  @include liste;
  .teaser-body .btn {
    background-color: $color-lead3;
    &:hover, &:focus {
      background-color: darken($color-lead3, 20);
    }
  }
}
