.stg_tagesimpuls-teaser {
  @include ecke(55px, 85px, $color-lead1);
  margin-bottom: 0 !important;
  .list-box {
    background: $color-gray;
    .ap-teaser .teaser-visual {
      margin-bottom: 0;
    }
    .teaser-body {
      position: initial;
      padding: 15px;
      .fully {
        padding-right: 30px;
        @media (max-width: 767px) {
          padding-left: 15px;
        }
      }
      .btn {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.stg_gottesdienst-teaser {
  @include ecke(35px, 40px, $color-lead2);
  margin-bottom: 40px;
  .btn {
    background-color: $color-lead2;
    &:hover, &:focus {
      background-color: darken($color-lead2, 20);
    }
  }
}

.stg_termine-teaser {
  @include ecke(55px, 45px, $color-lead3);
  &.ap-list-content .list-box {
    background: $color-gray;
    .list-entries {
      padding: 15px;
      .teaser-body {
        .fully {
          display: flex;
          flex-direction: column;
          .teaser-date {
            order: 1;
            font-size: 1.2rem;
          }
          .teaser-title {
            order: 2;
          }
          .teaser-headline {
            order: 3;
          }
          .teaser-text {
            order: 4;
          }
        }
        .teaser-btn {
          float: right;
          &:not(:hover) {
            background-color: $color-lead3;
            color: #fff;
          }
          &:hover, &:focus {
            background-color: darken($color-lead3, 20);
          }
          &::after {
            content: ' +'
          }
        }
      }
    }
    .list-pagination {
      .list-append-position {
        width: 100% !important;
        .btn {
          background-color: $color-lead3;
          &:hover, &:focus {
            background-color: darken($color-lead3, 20);
          }
        }
      }
    }
  }
}

.stg_nachrichten-teaser {
  @include ecke(85px, 55px, $color-lead4);
  .list-box {
		position: relative;
		&::before {
      left: 10px;
      @media (max-width: 767px) {
        left: 5px;
      }
    }
  }
  &.ap-list-content.ap-tiling-list .teaser-body {
    .fully {
      display: flex;
      flex-direction: column;
      padding-bottom: 0 !important;
      .teaser-date {
        order: 1;
      }
      .teaser-title {
        order: 2;
        min-height: 0;
      }
      .teaser-headline {
        order: 3;
      }
    }
  }
  .teaser-link {
    position: relative !important;
    .btn {
      background-color: $color-lead4;
      padding: 2px 10px;
      &:hover, &:focus {
        background-color: darken($color-lead4, 20);
      }
      &::after {
        content: ' +'
      }
    }
  }
  .list-pagination {
    .list-append-position {
      width: 100%;
      border-bottom: 2px solid $color-lead4;
      display: block;
      text-align: right;
      .btn {
        width: auto;
        display: inline-block;
        background-color: $color-lead4;
        &:hover, &:focus {
          background-color: darken($color-lead4, 20);
        }
      }
    }
  }
}
