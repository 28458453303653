.area-foot {
  margin-top: 80px;
  .stb_footerhead {
    margin-bottom: 15px;
    .head {
      @include farbstreifen(2px);
      h1, h2, h3, h4 {
        border-bottom: none;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
  .stb_footercontent {
    .head {
      h1, h2, h3, h4 {
        border-bottom: none;
        font-size: $font-content-size;
        font-weight: bold;
      }
    }
    .text {
      margin-top: 0;
    }
  }
  .subfoot {
    margin-top: 20px;
  }
}
