.bis-headgroup {
  .bis-logo .ap-image-section {
    max-width: 230px;
    margin-left: -30px;
  }
}

.head-navbar {
  margin-top: -66px;
  .container {
    @include farbstreifen(5px);
  }
}

.dropdown-menu {
  .head {
    min-height: 0;
    h1, h2, h3, h4, h5, h6 {
      color: $color-lead1;
      text-transform: $nav-header-text-transform;
      font-size: $nav-header-font-size;
      font-weight: 600; //$nav-header-font-weight;
      line-height: $nav-header-line-height;
      border-bottom: 1px solid $color-gray;
    }
  }
  li a {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.ap-header .breadcrumbs {
  margin-bottom: 30px;
  ul.breadcrumb {
    padding: 5px;
  }
}

.head-navbar {
  .navbar-nav {
    margin-top: 4em;
  }
}
